/*
*****JOURNAL*****

Documentation: https://docs.google.com/document/d/1FnauUXKWxIQazzVtds4ztAlWlPE4RtAPtsYvWlI3Qc8/edit

Type & Kind Question
- Type (journal): Journal Article (Ex. 10.1-10.10)
    - Kind (article): A single published article
    - Kind (foreign): Foreign language or translated article
    - Kind (advance): Advance online publication
    - Kind (inPress): In-press article
    - Kind (editorial): Editorial
[AuthorPart][DatePart][TitlePart][SourcePart]

- Type (reprinted): Reprinted Article (Ex. 10.11)
    - Kind (article): A single article
    - Kind (foreign): Foreign language or translated article
[AuthorPart][DatePart][TitlePart][Source Part][ReprintedSourcePart [OriginalArticleTitlePart][OriginalDatePart][OriginalJournalPart][OriginalSourcePart]]

- Type (special): Special Issue or Section (Ex. 10.12)
    - Kind (issue): Entire special issue 
    - Kind (section): Special section
[AuthorPart][DatePart][SectionTitlePart][SourcePart]


AuthorPart
    - Authors, Group Authors, Usernames, Editors

DatePart
    - Publication Date: either "YYYY", "YYYY, MM", "YYYY, MM DD", "n.d.", or "in-press" (the YYYYMMDD will cover all other periodicals)

TitlePart
    - Article Title, Translated Article Title, Journal Title, Translated Journal Title, Editors, Translators, Volume, Issue, Page or Article Numbers

SourcePart
    - DOI or URL

ReprintedSourcePart (Reprinted article)
    - OriginalArticleTitlePart
        - "(Reprinted from ", Original Article Title
    - OriginalDatePart
        - Original Publication Date: either "YYYY", "YYYY, MM", "YYYY, MM DD", or "n.d."
    - OriginalJournalPart
        - Original Journal Title, Original Volume, Original Issue, Original Page or Article Numbers
    - OriginalSourcePart
        - Original DOI or URL

SectionTitlePart (Special Section or Issue)
    - Section Title, Translated Section Title, "[Special Section]" or "[Special Issue]", Journal Title, Translated Journal Title, Editors, Translators, Volume, Issue, Page or Article Numbers (Section Only)
*/
const _forEach = require('lodash/forEach');
const shared = require('./APA7-Shared.js');
const stringHelper = require('../stringHelper.js');

module.exports = {
    getReference,
    getCitation
}

function getReference(refData) {

    let referenceValue = '';
    let type = shared.getStringValue(refData.type);
    let kind = shared.getStringValue(refData.kind);

    if (type.length == 0 || kind.length == 0) {
        return 'Type and Kind required.';
    }

    let authorPart = getAuthorPart(refData);
    let datePart = getDatePart(refData);
    let sourcePart = getSourcePart(refData);

    if (type == 'journal') {
        let titlePart = getTitlePart(refData);

        if (authorPart.length == 0) {
            //no contributors
            let title = shared.getStringValue(refData.articleTitle);
            authorPart = title + '.';

            titlePart = titlePart.replace(authorPart + ' ', '');
        }

        referenceValue = authorPart + ' ' + datePart + ' ' + titlePart + ' ' + sourcePart;

        if (kind == 'retracted') {
            let retractedSourcePart = getRetractedSourcePart(refData);

            if (retractedSourcePart.length > 0) {
                referenceValue = referenceValue +  ' ' + retractedSourcePart;
            }
        }
    }

    if (type == 'reprinted') {
        let titlePart = getTitlePart(refData);
        let reprintedSourcePart = getReprintedSourcePart(refData);

        if (authorPart.length == 0) {
            //no contributors
            referenceValue = titlePart + ' ' + datePart + ' ' + sourcePart + ' ' + reprintedSourcePart;
        } else {
            referenceValue = authorPart + ' ' + datePart + ' ' + titlePart + ' ' + sourcePart + ' ' + reprintedSourcePart;
        }
    }

    if (type == 'special') {
        let sectionTitlePart = getSectionTitlePart(refData);

        if (authorPart.length == 0) {
            //no contributors   
            let label = '';
            let title = '';

            if (refData.kind == 'issue') {
                label = '[Special issue]';
                title = shared.getStringValue(refData.issueTitle);
            }

            if (refData.kind == 'section') {
                label = '[Special section]';
                title = shared.getStringValue(refData.sectionTitle);
            }

            authorPart = title + ' ' + label + '.';

            sectionTitlePart = sectionTitlePart.replace(authorPart + ' ', '');
        }

        referenceValue = authorPart + ' ' + datePart + ' ' + sectionTitlePart + ' ' + sourcePart;
    }

    if (type == 'database') {
        let titlePart = getDatabaseTitlePart(refData);

        if (authorPart.length == 0) {
            //no contributors
            let title = shared.getStringValue(refData.articleTitle);
            authorPart = title + '.';

            titlePart = titlePart.replace(authorPart + ' ', '');
        }

        referenceValue = authorPart + ' ' + datePart + ' ' + titlePart + ' ' + sourcePart;
    }

    let reference = {
        value: referenceValue.trim(),
        orderByValue: shared.getOrderByValue(referenceValue),
        isPrintedOnReferencePage: true,
        authorPart: authorPart,
        datePart: datePart
    }

    return reference;
}

function getCitation(refData, citationData, paperRefs) {

    //for authors, we need to limit this to Authors and usernames
    let authorArray = [];

    _forEach(refData.contributors, (item) => {
        if (item.type == 'author' || item.type == 'groupAuthor' || item.type == 'chapterAuthor' || item.type == 'userName') {
            authorArray.push(item);
        }
    });

    let authorString = shared.getCitationAuthorString(authorArray, false, paperRefs);
    let year = shared.getYear(refData.publicationDate);

    //if there is not author string, we need to use the title
    if (authorString.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);

        if (editorArray.length > 0) {
            authorString = shared.getCitationAuthorString(editorArray, false, paperRefs);
        } else {

            let title = '';

            if (refData.kind == 'issue') {
                title = shared.getStringValue(refData.issueTitle);
            }

            if (refData.kind == 'section') {
                title = shared.getStringValue(refData.sectionTitle);
            }

            if (refData.type == 'journal' || refData.type == 'database') {
                title = shared.getStringValue(refData.articleTitle);
            }

            if (title.length > 0) {
                if (title.startsWith('[')) {
                    authorString = title;  
                }
                else{
                    authorString = '"' + title.toTitleCase() + '," ';  
                }  
            }
        }
    }

    if (refData.kind == 'inPress') {
        year = 'in press';
    }

    //if there is no year, use n.d.
    if (year.length == 0) {
        year = 'n.d.';
    }

    //if there ia publication date original also, include it before the publication date
    let publicationDateOriginal = shared.getYear(refData.originalPublicationDate);

    if (publicationDateOriginal.length == 0 && (refData.type == 'reprinted')) {
        publicationDateOriginal = 'n.d.';
    }

    if (publicationDateOriginal.length > 0) {
        year = publicationDateOriginal + '/' + year;
    }

    let citation = shared.getCitations(citationData, authorString, year, authorArray);

    return citation;
}

function getAuthorPart(refData) {
    let authorArray = shared.getAuthors(refData.contributors);
    let authorString = shared.getReferenceNameString(authorArray)

    let authorPart = authorString;

    if (authorPart.length > 0 && !authorPart.endsWith(".")) {
        authorPart = authorPart + '.'
    }

    if (authorPart.length == 0) {

        let editorArray = shared.getEditors(refData.contributors);
        //if there is not author, we need to check editors
        if (editorArray.length > 0) {
            authorPart = shared.getEditorsAtStartofRefString(editorArray);

            if (editorArray.length == 1) {
                authorPart = authorPart + ' (Ed.).';
            } else {
                if (editorArray.length > 1) {
                    authorPart = authorPart + ' (Eds.).';
                }
            }
        }
    }

    return authorPart;
}

function getDatePart(refData) {

    if (refData.kind == 'inPress') {
        return '(in press).';
    }

    //default the pub date to n.d. if we don't have one
    let publicationDate = shared.getStringValue(refData.publicationDate);
    let formattedDate = shared.getFullDate(publicationDate);

    let datePart = '(' + formattedDate + ').';

    return datePart;
}

function getTitlePart(refData) {
    let titlePart = '';

    let articleTitle = shared.getStringValue(refData.articleTitle);
    let translatedArticleTitle = shared.getStringValue(refData.translatedArticleTitle);
    let journalTitle = shared.getStringValue(refData.journalTitle);
    let volume = shared.getStringValue(refData.volume);
    let issue = shared.getStringValue(refData.issue);
    let referencePages = shared.getStringValue(refData.referencePages);
    let articleNumber = shared.getStringValue(refData.articleNumber);

    //editors
    let editorArray = shared.getEditors(refData.contributors);
    let editorString = shared.getReferenceNameString(editorArray, true);

    if (editorArray.length == 1) {
        editorString = editorString + ', Ed.';
    } else {
        if (editorArray.length > 1) {
            editorString = editorString + ', Eds.';
        }
    }

    //translators
    let translatorString = shared.getTranslatorString(refData.contributors);

    //editor and translator string
    //this is only the scenario where they all match and we don't need the individual string
    let editorTranslatorString = shared.getEditorTranslatorString(refData.contributors);

    if (editorTranslatorString.length > 0) {
        editorString = '';
        translatorString = '';
    }

    //build out the title part
    //Title of the article [Translated article title] (A. A. Editora, Ed.; A. A. Translatora, Trans.). <em>Journal Title</em>, <em>3</em>(2), 13–21, 27.
    if (translatedArticleTitle.length > 0 && articleTitle.length == 0) {
        articleTitle = translatedArticleTitle;
        translatedArticleTitle = '';
    }

    if (articleTitle.length > 0) {

        let authorPart = getAuthorPart(refData);

        if (authorPart.length > 0) {
            titlePart = articleTitle;    
        }
    }

    if (translatedArticleTitle.length > 0) {
        titlePart = titlePart + ' [' + translatedArticleTitle + ']';
    }

    let secondaryContribs = '';

    if (editorString.length > 0 ||
        translatorString.length > 0 ||
        editorTranslatorString.length > 0) {

        var isFirstNames = true;

        if (editorTranslatorString.length > 0) {
            secondaryContribs = editorTranslatorString;
            isFirstNames = false;
        }

        let authorArray = shared.getAuthors(refData.contributors);
        if (editorString.length > 0 && authorArray.length > 0) {
            secondaryContribs = editorString;
            isFirstNames = false;
        }

        if (translatorString.length > 0) {
            if (isFirstNames) {
                secondaryContribs = translatorString;
            } else {
                secondaryContribs = secondaryContribs + '; ' + translatorString;
            }
            isFirstNames = false;
        }

        if (secondaryContribs.length > 0) {
            titlePart = titlePart + ' (' + secondaryContribs + ')';
        }
    }

    if (refData.kind == 'editorial') {
        titlePart = titlePart + ' [Editorial]';
    }

    if (journalTitle.length > 0) {
        if (titlePart.length > 0) {
            titlePart = shared.fixTitlePartClosure(titlePart) + ' ';
        }

        titlePart = titlePart + journalTitle.italicize();
    }

    if (volume.length > 0) {

        if (titlePart.length > 0) {
            titlePart = titlePart + ', ';
        }

        titlePart = titlePart + volume.italicize();
    }

    if (issue.length > 0) {
        if (volume.length == 0) {
            titlePart = titlePart + ', ';
        }

        titlePart = titlePart + '(' + issue + ')';
    }

    if (referencePages.length > 0) {
        if (titlePart.length > 0) {
            titlePart = titlePart + ', ';
        }

        referencePages = shared.fixPageDash(referencePages);

        titlePart = titlePart + referencePages;
    }

    if (articleNumber.length > 0 && referencePages.length == 0) {
        if (titlePart.length > 0) {
            titlePart = titlePart + ', ';
        }

        titlePart = titlePart + 'Article ' + articleNumber;
    }

    titlePart = shared.fixTitlePartClosure(titlePart);

    return titlePart;
}

function getDatabaseTitlePart(refData) {
    let titlePart = '';

    let articleTitle = shared.getStringValue(refData.articleTitle);
    let translatedArticleTitle = shared.getStringValue(refData.translatedArticleTitle);
    let journalTitle = shared.getStringValue(refData.journalTitle);
    let volume = shared.getStringValue(refData.volume);
    let issue = shared.getStringValue(refData.issue);
    let referencePages = shared.getStringValue(refData.referencePages);
    let articleNumber = shared.getStringValue(refData.articleNumber);
    let databaseTitle = shared.getStringValue(refData.databaseTitle);

    //editors
    let editorArray = shared.getEditors(refData.contributors);
    let editorString = shared.getReferenceNameString(editorArray, true);

    if (editorArray.length == 1) {
        editorString = editorString + ', Ed.';
    } else {
        if (editorArray.length > 1) {
            editorString = editorString + ', Eds.';
        }
    }

    //translators
    let translatorString = shared.getTranslatorString(refData.contributors);

    //editor and translator string
    //this is only the scenario where they all match and we don't need the individual string
    let editorTranslatorString = shared.getEditorTranslatorString(refData.contributors);

    if (editorTranslatorString.length > 0) {
        editorString = '';
        translatorString = '';
    }

    //build out the title part
    //Title of the article [Translated article title] (A. A. Editora, Ed.; A. A. Translatora, Trans.). <em>Journal Title</em>, <em>3</em>(2), 13–21, 27.
    if (translatedArticleTitle.length > 0 && articleTitle.length == 0) {
        articleTitle = translatedArticleTitle;
        translatedArticleTitle = '';
    }

    if (articleTitle.length > 0) {
        titlePart = articleTitle;
    }

    if (translatedArticleTitle.length > 0) {
        titlePart = titlePart + ' [' + translatedArticleTitle + ']';
    }

    let secondaryContribs = '';

    if (editorString.length > 0 ||
        translatorString.length > 0 ||
        editorTranslatorString.length > 0) {

        var isFirstNames = true;

        if (editorTranslatorString.length > 0) {
            secondaryContribs = editorTranslatorString;
            isFirstNames = false;
        }

        let authorArray = shared.getAuthors(refData.contributors);
        if (editorString.length > 0 && authorArray.length > 0) {
            secondaryContribs = editorString;
            isFirstNames = false;
        }

        if (translatorString.length > 0) {
            if (isFirstNames) {
                secondaryContribs = translatorString;
            } else {
                secondaryContribs = secondaryContribs + '; ' + translatorString;
            }
            isFirstNames = false;
        }

        if (secondaryContribs.length > 0) {
            titlePart = titlePart + ' (' + secondaryContribs + ')';
        }
    }

    if (refData.kind == 'editorial') {
        titlePart = titlePart + ' [Editorial]';
    }

    if (databaseTitle.length == 0) {
        if (journalTitle.length > 0) {
            if (titlePart.length > 0) {
                titlePart = titlePart + '. ';
            }

            titlePart = titlePart + journalTitle.italicize();
        }

        if (volume.length > 0) {

            if (titlePart.length > 0) {
                titlePart = titlePart + ', ';
            }

            titlePart = titlePart + volume.italicize();
        }

        if (issue.length > 0) {
            if (volume.length == 0) {
                titlePart = titlePart + ', ';
            }

            titlePart = titlePart + '(' + issue + ')';
        }

        if (referencePages.length > 0) {
            if (titlePart.length > 0) {
                titlePart = titlePart + ', ';
            }

            referencePages = shared.fixPageDash(referencePages);

            titlePart = titlePart + referencePages;
        }

        if (articleNumber.length > 0 && referencePages.length == 0) {
            if (titlePart.length > 0) {
                titlePart = titlePart + ', ';
            }

            titlePart = titlePart + 'Article ' + articleNumber;
        }
        
    }

    if (databaseTitle.length > 0) {
        if (titlePart.length > 0) {
            titlePart = titlePart + '. ';
        }

        titlePart = titlePart + databaseTitle.italicize();
    }

    titlePart = shared.fixTitlePartClosure(titlePart);

    return titlePart;
}

function getSectionTitlePart(refData) {
    //Section Title, Translated Section Title, "[Special Section]" or "[Special Issue]", Journal Title, Translated Journal Title, Editors, Translators, Volume, Issue, Page or Article Numbers (Section Only)
    //Title of the section [Translated section title] (A. A. Translatora, & B. B. Translatorb, Jr., Trans.) [Special section]. <em>Journal Title</em>, <em>3</em>(2), 13–21, 27.
    let sectionTitlePart = '';

    // "sectionTitle" : "Title of the section",
    //             "translatedSectionTitle" : "Translated section title",
    //             "journalTitle" : "Journal Title",
    //             "publicationDate" : "//2019",
    //             "volume" : "3",
    //             "issue" :  "2",
    //             "referencePages" : "13–21, 27",
    //             "retrievalDate" : "5/15/2019"

    let sectionTitle = shared.getStringValue(refData.sectionTitle);
    let translatedSectionTitle = shared.getStringValue(refData.translatedSectionTitle);
    let issueTitle = shared.getStringValue(refData.issueTitle);
    let translatedIssueTitle = shared.getStringValue(refData.translatedIssueTitle);
    let translatorString = shared.getTranslatorString(refData.contributors);
    let journalTitle = shared.getStringValue(refData.journalTitle);
    let volume = shared.getStringValue(refData.volume);
    let issue = shared.getStringValue(refData.issue);
    let referencePages = shared.getStringValue(refData.referencePages);

    //editors
    let editorArray = shared.getEditors(refData.contributors);
    let authorArray = shared.getAuthors(refData.contributors);
    let editorString = '';

    if (authorArray.length > 0) {
        editorString = shared.getReferenceNameString(editorArray, true);

        if (editorArray.length == 1) {
            editorString = editorString + ', Ed.';
        } else {
            if (editorArray.length > 1) {
                editorString = editorString + ', Eds.';
            }
        }
    }

    if (refData.kind == 'section') {
        sectionTitlePart = sectionTitle;

        if (translatedSectionTitle.length) {
            if (sectionTitlePart.length > 0) {
                sectionTitlePart = sectionTitlePart + ' ';
            }

            sectionTitlePart = sectionTitlePart + '[' + translatedSectionTitle + ']';
        }
    }

    if (refData.kind == 'issue') {
        sectionTitlePart = issueTitle;

        if (translatedIssueTitle.length) {
            if (sectionTitlePart.length > 0) {
                sectionTitlePart = sectionTitlePart + ' ';
            }

            sectionTitlePart = sectionTitlePart + '[' + translatedIssueTitle + ']';
        }
    }

    if (translatorString.length > 0 || editorString.length > 0) {
        if (sectionTitlePart.length > 0) {
            sectionTitlePart = sectionTitlePart + ' ';
        }

        // sectionTitlePart = sectionTitlePart + '(' + translatorString + ')';
        sectionTitlePart = sectionTitlePart + '(';
        sectionTitlePart = sectionTitlePart + editorString;

        if (translatorString.length > 0 && editorString.length > 0) {
            sectionTitlePart = sectionTitlePart + '; ';
        }

        sectionTitlePart = sectionTitlePart + translatorString;
        sectionTitlePart = sectionTitlePart+ ')';
    }

    if (refData.kind == 'section') {
        sectionTitlePart = sectionTitlePart + ' [Special section].';
    }

    if (refData.kind == 'issue') {
        sectionTitlePart = sectionTitlePart + ' [Special issue].';
    }

    if (journalTitle.length > 0) {
        if (sectionTitlePart.length > 0) {
            sectionTitlePart = sectionTitlePart + ' ';
        }

        sectionTitlePart = sectionTitlePart + journalTitle.italicize();
    }

    if (volume.length > 0) {
        if (sectionTitlePart.length > 0) {
            sectionTitlePart = sectionTitlePart + ',';
        }

        sectionTitlePart = sectionTitlePart + ' ' + volume.italicize();
    }

    if (issue.length > 0) {
        sectionTitlePart = sectionTitlePart + '(' + issue + ')';
    }

    if (referencePages.length > 0) {
        sectionTitlePart = sectionTitlePart + ', ' + shared.fixPageDash(referencePages);
    }

    sectionTitlePart = shared.fixTitlePartClosure(sectionTitlePart);

    return sectionTitlePart;
}

function getSourcePart(refData) {
    //Retrieved May 15, 2019 from https://doi.org/10.123456789 (Original work published 1945)
    let sourcePart = '';

    let retrievalDate = shared.getStringValue(refData.retrievalDate);
    let doi = shared.getStringValue(refData.doi);
    let url = shared.getStringValue(refData.url);
    let originalPublicationDate = shared.getStringValue(refData.originalPublicationDate);

    if (refData.kind == 'advance') {
        sourcePart = 'Advance online publication.';
    }

    if (retrievalDate.length > 0) {

        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        sourcePart = sourcePart + 'Retrieved';
        sourcePart = sourcePart + ' ' + shared.formatRetrievalDate(retrievalDate);
        sourcePart = sourcePart + ', from';
    }

    if (doi.length > 0) {
        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        sourcePart = sourcePart + shared.appendDOIURL(doi);
    } else {
        if (url.length > 0) {
            url = shared.formatURL(url);
            if (sourcePart.length > 0) {
                sourcePart = sourcePart + ' ';
            }

            sourcePart = sourcePart + url;
        }
    }

    if (originalPublicationDate.length > 0 && refData.type != 'reprinted') {

        if (sourcePart.length > 0) {
            sourcePart = sourcePart + ' ';
        }

        let publicationDateYear = shared.getYear(originalPublicationDate);
        sourcePart = sourcePart + '(Original work published ' + publicationDateYear + ')';

    }

    return sourcePart;
}

function getReprintedSourcePart(refData) {
    let reprintedSourcePart = '';
    //(Reprinted from “Original article title,” 1945, Original Journal Name, <em>3</em>[2], 13–21, 27, https://doi.org/10.987654321)

    let originalArticleTitle = shared.getStringValue(refData.originalArticleTitle);
    let originalPublicationDate = shared.getYear(refData.originalPublicationDate);
    let originalJournalTitle = shared.getStringValue(refData.originalJournalTitle);
    let originalVolume = shared.getStringValue(refData.originalVolume);
    let originalIssue = shared.getStringValue(refData.originalIssue);
    let originalPages = shared.getStringValue(refData.originalPages);
    let originalDoi = shared.getStringValue(refData.originalDoi);
    let originalUrl = shared.getStringValue(refData.originalUrl);

    //build the part
    reprintedSourcePart = '(Reprinted from';

    if (originalArticleTitle.length > 0) {
        reprintedSourcePart = reprintedSourcePart + ' "' + originalArticleTitle + ',"';
    }

    if (originalPublicationDate.length > 0) {
        reprintedSourcePart = reprintedSourcePart + ' ' + originalPublicationDate;
    }

    if (originalJournalTitle.length > 0) {
        if (!reprintedSourcePart.endsWith(',') || !reprintedSourcePart.endsWith(',"')) {
            reprintedSourcePart = reprintedSourcePart + ",";
        }

        reprintedSourcePart = reprintedSourcePart + ' ' + originalJournalTitle.italicize();
    }

    if (originalVolume.length > 0) {
        if (!reprintedSourcePart.endsWith(',') || !reprintedSourcePart.endsWith(',"')) {
            reprintedSourcePart = reprintedSourcePart + ", ";
        }

        reprintedSourcePart = reprintedSourcePart + originalVolume.italicize();
    }

    if (originalIssue.length > 0) {
        reprintedSourcePart = reprintedSourcePart + '[' + originalIssue + ']';
    }

    if (originalPages.length > 0) {
        if (!reprintedSourcePart.endsWith(',') || !reprintedSourcePart.endsWith(',"')) {
            reprintedSourcePart = reprintedSourcePart + ", ";
        }

        reprintedSourcePart = reprintedSourcePart + shared.fixPageDash(originalPages);
    }

    if (originalDoi.length > 0) {
        if (!reprintedSourcePart.endsWith(',') || !reprintedSourcePart.endsWith(',"')) {
            reprintedSourcePart = reprintedSourcePart + ", ";
        }

        reprintedSourcePart = reprintedSourcePart + shared.appendDOIURL(originalDoi);
    } else {
        if (originalUrl.length > 0) {
            if (!reprintedSourcePart.endsWith(',') || !reprintedSourcePart.endsWith(',"')) {
                reprintedSourcePart = reprintedSourcePart + ",";
            }

            originalUrl = shared.formatURL(originalUrl);
            reprintedSourcePart = reprintedSourcePart + ' ' + originalUrl;
        }
    }

    reprintedSourcePart = reprintedSourcePart + ')';

    return reprintedSourcePart;
}

function getRetractedSourcePart(refData) {
    //(Retraction published April 1, 2018, <em>Journal Title</em>, 2[3], 12–13, https://www.perrla.com/retraction)
    let retractedSourcePart = '';
    let retractionDate = shared.getStringValue(refData.retractionDate);
    let retractionJournal = shared.getStringValue(refData.journalTitle);
    let retractionVolume = shared.getStringValue(refData.retractionVolume);
    let retractionIssue = shared.getStringValue(refData.retractionIssue);
    let retractionPages = shared.getStringValue(refData.retractionPages);
    let retractionURL = shared.getStringValue(refData.retractionURL);
    let retractionDOI = shared.getStringValue(refData.retractionDOI);
    let retractionArticleNumber = shared.getStringValue(refData.retractionArticleNumber);

    if (retractionDate.length > 0) {
        retractedSourcePart = retractedSourcePart + shared.getReverseFullDate(retractionDate);
    }

    if (retractionJournal.length > 0) {

        if (retractedSourcePart.length > 0) {
            retractedSourcePart = retractedSourcePart + ', ';
        }
        
        retractedSourcePart = retractedSourcePart + retractionJournal.italicize();
    }

    if (retractionVolume.length > 0) {
        if (!retractedSourcePart.endsWith(',') || !retractedSourcePart.endsWith(',"')) {
            retractedSourcePart = retractedSourcePart + ", ";
        }

        retractedSourcePart = retractedSourcePart + retractionVolume.italicize();
    }

    if (retractionIssue.length > 0) {

        if (retractionVolume.length == 0) {
            retractedSourcePart = retractedSourcePart + ", ";
        }

        retractedSourcePart = retractedSourcePart + '[' + retractionIssue + ']';
    }

    if (retractionPages.length > 0) {
        if (!retractedSourcePart.endsWith(',') || !retractedSourcePart.endsWith(',"')) {
            retractedSourcePart = retractedSourcePart + ", ";
        }

        retractedSourcePart = retractedSourcePart + shared.fixPageDash(retractionPages);
    }

    if (retractionArticleNumber.length > 0) {
        if (!retractedSourcePart.endsWith(',') || !retractedSourcePart.endsWith(',"')) {
            retractedSourcePart = retractedSourcePart + ", ";
        }

        if (!retractionArticleNumber.startsWith('Article')) {
            retractionArticleNumber = "Article " + retractionArticleNumber
        }

        retractedSourcePart = retractedSourcePart + retractionArticleNumber;
    }

    if (retractionDOI.length > 0) {
        if (!retractedSourcePart.endsWith(',') || !retractedSourcePart.endsWith(',"')) {
            retractedSourcePart = retractedSourcePart + ", ";
        }

        retractedSourcePart = retractedSourcePart + shared.appendDOIURL(retractionDOI);
    } else {
        if (retractionURL.length > 0) {
            if (!retractedSourcePart.endsWith(',') || !retractedSourcePart.endsWith(',"')) {
                retractedSourcePart = retractedSourcePart + ",";
            }

            retractionURL = shared.formatURL(retractionURL);
            retractedSourcePart = retractedSourcePart + ' ' + retractionURL;
        }
    }

    if (retractedSourcePart.length > 0) {
        retractedSourcePart = '(Retraction published ' + retractedSourcePart + ')';
    }

    return retractedSourcePart;
}