// RS_Loop
// loops through a block of references from /Sync
// should be called twice once for Genesis and another time for non-Genesis references
// 2024.08.05 

import _cloneDeep from 'lodash/cloneDeep';
import _findIndex from 'lodash/findIndex';
import _forEach from 'lodash/forEach';
import _uniqBy from 'lodash/uniqBy';
import config from '@/config';
import RS_Compress from '@/services/reference/compress';
import store from '@/store';

export default ($opts) => {
	return new Promise((resolve) => {
		// console.log('RS_Loop');

		// All references are returned here but only add ones that are not "Deleted"
		let currentRefLibArray = _cloneDeep($opts.currentRefLibArray);

		let undeletedReferenceLibrary = [];

		if($opts.decompressReferences.length === 0){
			// NO NEW REFERENCES - skip processing
			if($opts.isGenesis){
				store.commit('REFERENCE_LIBRARY_GENESIS', currentRefLibArray);
			} else {
				store.commit('REFERENCE_LIBRARY', currentRefLibArray);
			}

			return resolve();

		} else {
			// NEW REFERENCES FROM SYNC - loop and recompress
			$opts.decompressReferences.forEach((ref) => {
				// console.log(ref);

				// Add this reference to the list
				if($opts.isGenesis){
					if(!ref.isDeleted){
						// Genesis Only (APA7 and MLA9) & Not Deleted
						// console.log(ref.isDeleted);
						
						// Look for this reference in the current library
						let findDuplicateIndex = _findIndex(currentRefLibArray, (currentRef) => {
							// weird case difference between the platforms, worry about it later
							return currentRef.referenceUniqueID === ref.ReferenceUniqueID || currentRef.referenceUniqueID === ref.referenceUniqueID;
						});
						// console.log('currentRefLibArray[findDuplicateIndex]');
						// console.log(currentRefLibArray[findDuplicateIndex]);

						// if found - remove that reference from the library so the new data will override it
						if(findDuplicateIndex != -1){
							// 2023.07.24 - comment this out for te Word Add In - it was used for syncing things up between the platforms - but the logic isn't quite right yet
							if(config.platformId === config.enums.Platform.ONLINE){ //config.platformId === config.enums.Platform.ADD_IN
								// console.log('ref');
								// console.log(ref);
								store.commit('references/ADD_TO_UPDATE_AFTER_SYNC', ref);
							}
							currentRefLibArray.splice(findDuplicateIndex, 1);
						}
						
						undeletedReferenceLibrary.push({
							// annotation: ref.Annotation,
							// citationType: ref.CitationType,
							createdDate: ref.createdDate,
							// createdFormatVersionId: ref.createdFormatVersionID || 0,
							customerID: ref.customerID,
							data: ref.data,
							// formatID: ref.FormatID,
							// formatVersionID: ref.FormatVersionID,
							isDeleted: ref.isDeleted,
							legacyReferenceId: ref.legacyReferenceID,
							// legacyReferenceData: ref.LegacyReferenceData,
							name: ref.name,
							nickname: ref.nickname,
							note: ref.note,
							// orderByValue: ref.OrderByValue,
							referenceID: ref.referenceID,
							referenceType: ref.referenceType,
							referenceTypeID: ref.referenceTypeID,
							referenceUniqueID: ref.referenceUniqueID,
							researchNotes: ref.researchNotes,	// keep this here now, it will get removed after the looping
							tags: ref.tags,
							values: ref.values,
						});

					}//e:if:else:ref.isDeleted

				} else {
					// Regular (APA6 & MLA8)
					undeletedReferenceLibrary.push({
						annotation: ref.annotation,
						citationType: ref.citationType,
						createdDate: ref.createdDate,
						customerID: ref.customerID,
						formatID: ref.formatID,
						formatVersionID: ref.formatVersionID,
						isDeleted: ref.isDeleted,
						isPrintedOnReferencePage: ref.isPrintedOnReferencePage,
						legacyReferenceData: ref.legacyReferenceData,
						name: ref.name,
						nickname: ref.nickname,
						note: ref.note,
						orderByValue: ref.orderByValue,
						referenceID: ref.referenceID,
						referenceType: ref.referenceType,
						referenceTypeID: ref.referenceTypeID,
						referenceUniqueID: ref.referenceUniqueID,
						tags: ref.tags,
						value: ref.value,
					});
				
				}//e:if:else:$opts.isGenesis

			});//e:forEach

			// combine previous ref library with the new data generated
			let mergedArray = currentRefLibArray.concat(undeletedReferenceLibrary);
				
			// remove duplicates
			mergedArray = _uniqBy(mergedArray, 'referenceUniqueID');

			if($opts.isGenesis){
				store.commit('REFERENCE_LIBRARY_GENESIS', mergedArray);
			} else {
				store.commit('REFERENCE_LIBRARY', mergedArray);
			}
		
			// dispatch to compress the referenceLibrary
			RS_Compress({
				isGenesis: $opts.isGenesis
			}).then(()=>{
				return resolve();
			});

		}//e:if:$opts.decompressReferences.length

	});//e:Promise			
}
