/*
- RS_GetValueOutput
-- get the value for a row or something
-- 2024.08.05
*/
import _has from 'lodash/has';
import config from '@/config';

export default ($opts) =>{
	// console.log('RS_GetValueOutput');
	
	const _$opts = $opts || {};
	const _displayFormatVersionId = (_has(_$opts, 'displayFormatVersionId')) ? _$opts.displayFormatVersionId : config.enums.Format.APA7;
	
	let displayValue = '';

	if(_has($opts.referenceData, 'values')){
		let parsedValues = JSON.parse($opts.referenceData.values);
		
		switch(_displayFormatVersionId){
			case config.enums.Format.APA7:
				if(_has(parsedValues, 'apa7') && parsedValues.apa7.value){
					displayValue = parsedValues.apa7.value;
				} else if(_has(parsedValues, 'mla9') && parsedValues.mla9.value){
					displayValue = parsedValues.mla9.value;
				} else if(_has(parsedValues, 'turabian9') && parsedValues.turabian9.value){
					displayValue = parsedValues.turabian9.value;
				}
				break;
			case config.enums.Format.MLA9:
				if(_has(parsedValues, 'mla9') && parsedValues.mla9.value){
					displayValue = parsedValues.mla9.value;
				} else if(_has(parsedValues, 'apa7') && parsedValues.apa7.value){
					displayValue = parsedValues.apa7.value;
				} else if(_has(parsedValues, 'turabian9') && parsedValues.turabian9.value){
					displayValue = parsedValues.turabian9.value;
				}
				break;
			case config.enums.Format.Turabian9:
				if(_has(parsedValues, 'turabian9') && parsedValues.turabian9.value){
					displayValue = parsedValues.turabian9.value;
				} else if(_has(parsedValues, 'apa7') && parsedValues.apa7.value){
					displayValue = parsedValues.apa7.value;
				} else if(_has(parsedValues, 'mla9') && parsedValues.mla9.value){
					displayValue = parsedValues.mla9.value;
				}
				break;
			default:
				displayValue = $opts.referenceData.value;
		}
		
		if(!displayValue || displayValue === '' || displayValue == 'Type and Kind required.'){
			console.log('parsedValues');
			console.log(parsedValues);
			displayValue = '<span class="text-danger">---</span>';
		}

		return displayValue;


	} else {
		// return 'LEGACY VALUE'
		return $opts.referenceData.value;
	}//e:if:isGenesis

}